body,
html {
  margin: 0;
  padding: 0;
  font-family: 'Roboto', sans-serif, -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue';
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

/* width */
::-webkit-scrollbar {
  width: 6px;
  height: 6px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #1b1b23;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #32487f;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #7B61FF;
}
.rowHighlighted{
  background-color: 'red';
}

.highlight{
  /* background: #555e009d; */
  /* background: #5e38009d; */
  /* background: #feae0056; */
  /* background: #805028; */
  background: #4B3E91;
  /* color: black; */
}

.dot-pulse {
  position: relative;
  left: -9999px;
  width: 10px;
  height: 10px;
  border-radius: 5px;
  background-color: #9880ff;
  color: #9880ff;
  box-shadow: 9999px 0 0 -5px;
  animation: dot-pulse 1.5s infinite linear;
  animation-delay: 0.25s;
}
.dot-pulse::before, .dot-pulse::after {
  content: "";
  display: inline-block;
  position: absolute;
  top: 0;
  width: 10px;
  height: 10px;
  border-radius: 5px;
  background-color: #9880ff;
  color: #9880ff;
}
.dot-pulse::before {
  margin: '100px';
  box-shadow: 9984px 0 0 -5px;
  animation: dot-pulse-before 1.5s infinite linear;
  animation-delay: 0s;
}
.dot-pulse::after {
  box-shadow: 10014px 0 0 -5px;
  animation: dot-pulse-after 1.5s infinite linear;
  animation-delay: 0.5s;
}

@keyframes dot-pulse-before {
  0% {
    box-shadow: 9984px 0 0 -5px;
  }
  30% {
    box-shadow: 9984px 0 0 2px;
  }
  60%, 100% {
    box-shadow: 9984px 0 0 -5px;
  }
}
@keyframes dot-pulse {
  0% {
    box-shadow: 9999px 0 0 -5px;
  }
  30% {
    box-shadow: 9999px 0 0 2px;
  }
  60%, 100% {
    box-shadow: 9999px 0 0 -5px;
  }
}
@keyframes dot-pulse-after {
  0% {
    box-shadow: 10014px 0 0 -5px;
  }
  30% {
    box-shadow: 10014px 0 0 2px;
  }
  60%, 100% {
    box-shadow: 10014px 0 0 -5px;
  }
}

@keyframes bgcolorchange {
  0% {
    color: #2d2a5b;
  }

  50% {
    background-color: #2d2a5b;
  }
}

.rotate {
  position: absolute;
  animation: rotate 1s linear;
}

.rotateInfinite{
  position: absolute;
  animation: rotate 2s infinite linear;
}

@keyframes rotate {
  0% {
    transform: rotateY(0deg);
  }
  50% {
    transform: rotateY(180deg);
  }
  100% {
    transform: rotateY(360deg);
  }
}

.flicker-border {
  animation: flicker 0.5s infinite alternate;
}

@keyframes flicker {
  0% {
    border: none;
  }
  100% {
    outline: 2px solid rgb(217, 130, 43);
  }
}

.user-guide-dialog-open {
    animation: 1s slide forwards;
  }

  @keyframes slide {
    from {
      margin-right: 32px;
    }
    to {
      margin-right: 30%;
    }
  }

.user-guide-dialog-close {
    animation: 1s slideout forwards;
  }

  @keyframes slideout {
    from {
      margin-right: 30%;
    }
    to {
      margin-right: 32px;
    }
  }
  
  .fade-in {
    animation: 0.5s fadeIn forwards;
  }
  
  @keyframes fadeIn {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }

  .fade-out {
    animation: 0.5s fadeOut forwards;
  }
  
  @keyframes fadeOut {
    0% {
      opacity: 1;
    }
    100% {
      opacity: 0;
    }
  }
  
  .MuiTooltip-popper[data-popper-placement*="bottom-start"] .MuiTooltip-tooltip {
    margin-top: 6px;
}

@keyframes blink-animation {
  0% { background-color: transparent; }
  50% { background-color: #392772; }
  100% { background-color: transparent; }
}

.blink {
  animation: blink-animation 1s infinite;
}